import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { getUser } from '../Store/Reducers/user';
import { useGetLeaderBoardQuery } from '../Services/queryApi';
import { eventId } from '../config';

export default function LeaderboardScreen() {
  const user = useSelector(getUser);
  const [value, setValue] = React.useState(0);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  let token;
  for (let param of query.entries()) {
    if (param[0] === 'token') {
      token = param[1];
    }
  }

  const {
    data: leaderboard = [],
    error: leaderboardError,
    isLoading: leaderboardLoading,
    currentData: currentLeaderboard,
  } = useGetLeaderBoardQuery({ eventId, token }, { pollingInterval: 30000 }); // Refresh every 30 seconds

  if (leaderboardLoading) {
    return (
      <Grid container>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <CircularProgress color="inherit" />
          <Typography variant="h4" color="textPrimary">
            Loading...
          </Typography>
        </Grid>
      </Grid>
    );
  }
  if (leaderboardError) {
    return (
      <Grid container>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography variant="h4" color="textPrimary">
            Error: {leaderboardError}
          </Typography>
        </Grid>
      </Grid>
    );
  }
  const leaderboardResults = leaderboard || currentLeaderboard;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    // @ts-ignore
    <TableContainer id="leaderboard-tabpanel" component={Paper}>
      <Table stickyHeader aria-label="leaderboard table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Points</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {leaderboardResults.map(
            ({ first_name, last_name, total_points }, index) => (
              <TableRow
                key={`leaderboard_${first_name ? first_name : index}_${
                  last_name ? last_name : index
                }_${total_points}`}
              >
                <TableCell component="th" scope="row">
                  {index + 1}. {first_name} {last_name}
                </TableCell>
                <TableCell align="right">{total_points}</TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
