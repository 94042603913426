import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getChatWindow,
  setChatWindow,
  getOpenProfile,
} from '../Store/Reducers/twilio';
import ChatSidebarMessages from '../Components/ChatSidebarMessages';
import MyEngagementZoneProfile from '../Components/MyEngagementZoneProfile';
import LaunchIcon from '@material-ui/icons/Launch';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@material-ui/core';
import isOnline from '../Utilities/isOnline';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

export default function ChatSidebar() {
  const dispatch = useDispatch();
  const history = useHistory();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const online = useSelector(isOnline);

  const isOpen = useSelector(getChatWindow);
  const [isMessage, setIsMessage] = useState(true);
  const [isOpenRooms, setIsOpenRooms] = useState(true);
  const [isDirectMessages, setIsDirectMessages] = useState(true);
  const [isCreateNewMessage, setIsCreateNewMessage] = useState(false);
  const [isSettings, setIsSettings] = useState(false);
  const notification = useSelector((state) => state.twilio?.notification);

  // const [text, setText] = useState('');

  // const user = useSelector((state) => state.user.user);
  // const contacts = useSelector((state) => state.twilio?.contacts);

  // console.log('🚀 ~ file: ChatSidebar.js ~ line 32 ~ ChatSidebar ~ user', user);
  // const openConversation = useSelector(getOpenConversation);
  // const conversationsObject = useSelector(getConversations);
  // const conversations = Object.keys(conversationsObject).map(
  //   (key) => conversationsObject[key]
  // );
  const openProfile = useSelector(getOpenProfile);

  const location = useLocation();
  // console.log('location: ', location);
  if (location.pathname === '/engagementzone') {
    return null;
  }

  if (isMobile && !online) {
    return null;
  }
  if (isMobile) {
    return (
      <div
        style={{ ...styles.mobileRoot, cursor: 'pointer' }}
        onClick={() => {
          // dispatch(setChatWindow(true));
        }}
      >
        <div
          style={{
            position: 'absolute',
            // left: -25,
            // top: -10,
            width: 60,
            height: 60,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            history.push(`/engagementzone`);
          }}
        >
          <MyEngagementZoneProfile />
        </div>
      </div>
    );
  }
  if (isOpen) {
    return (
      <div style={styles.chatRoot}>
        <div style={{ ...styles.header, justifyContent: 'space-between' }}>
          <div
            style={{
              position: 'absolute',
              left: -25,
              top: -10,
              zIndex: 1210,
            }}
          >
            {/* <ChatProfile identity={getTwilioUserIdentity(user)} size={60} /> */}
            <MyEngagementZoneProfile />
          </div>
          <div
            style={{
              marginLeft: 30,
              fontSize: 16,
              fontWeight: 'bold',
              marginRight: 8,
              width: '50%',
            }}
          >
            Chat
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '23%',
            }}
          >
            <Tooltip
              title={
                online ? (
                  <Typography variant="subtitle2">Open Chat Page</Typography>
                ) : (
                  <Typography variant="subtitle2">
                    User is Offline. Cannot perform action.
                  </Typography>
                )
              }
            >
              <div
                style={{ margin: 8, cursor: 'pointer' }}
                onClick={() => {
                  if (online) {
                    console.log('location: ', window.location);
                    let url = `${window.location.protocol}//${window.location.host}/engagementzone`;
                    window.open(url, 'engagementzone');
                  }
                }}
              >
                <LaunchIcon />

                {/* <img src={exportIcon} style={{ width: 18, height: 18 }} /> */}
              </div>
            </Tooltip>
            {/* <div
              style={{ margin: 8 }}
              onClick={async (e) => {
                e.stopPropagation();
                setIsSettings(true);
              }}
            >
              <img src={setting} style={{ width: 18, height: 18 }} />
            </div> */}
            <a
              title={'Close'}
              style={{
                // position: 'absolute',
                // right: 0,
                // paddingRight: 25,
                cursor: 'pointer',
              }}
              onClick={() => {
                dispatch(setChatWindow(false));
              }}
            >
              {/* KeyboardArrowDownIcon */}
              <KeyboardArrowDownIcon style={{ width: 30, height: 30 }} />
              {/* <img src={down} style={{ width: 16, height: 10 }} /> */}
            </a>
          </div>
        </div>
        {/* <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            boxShadow: '0 0 15px -7px #000',
            zIndex: 1200,
          }}
        >
          <div
            style={{
              ...styles.tabs,
              borderBottom: isMessage ? '2px solid' : '0px solid',
              color: isMessage ? '#122857' : 'black',
              fontWeight: isMessage ? 'bold' : 'normal',
            }}
            onClick={() => {
              setIsMessage(true);
              dispatch(setOpenConversation(null));
              dispatch(setOpenProfile(null));
              setIsCreateNewMessage(false);
            }}
          >
            Chat
          </div>
          <div
            style={{
              ...styles.tabs,
              borderBottom: !isMessage ? '2px solid' : '0px solid',
              color: !isMessage ? '#122857' : 'black',
              fontWeight: !isMessage ? 'bold' : 'normal',
            }}
            onClick={() => {
              setIsMessage(false);
            }}
          >
            Calendar
          </div>
        </div> */}
        {online && (
          <div
            style={{
              // overflowY: 'auto',
              overflow: 'hidden',
              borderBottomRightRadius: 20,
              borderBottomLeftRadius: 20,
              width: '100%',
              flex: 1,
            }}
          >
            {isMessage ? <ChatSidebarMessages /> : <div>calendar</div>}
          </div>
        )}
        {!online && (
          <div
            style={{
              // overflowY: 'auto',
              overflow: 'hidden',
              borderBottomRightRadius: 20,
              borderBottomLeftRadius: 20,
              width: '100%',
              height: '100%',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              color: 'black',
              fontWeight: 'bold',
              display: 'flex',
            }}
          >
            <div>User is Offline</div>
          </div>
        )}

        {/* {isSettings && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              backgroundColor: 'rgba(0,0,0,0.2)',
              flex: 1,
              borderBottomRightRadius: 20,
              borderBottomLeftRadius: 20,
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              left: 0,
              zIndex: 100000,

              // backgroundColor: 'green',
            }}
            onClick={() => setIsSettings(false)}
          >
            <div
              style={{
                position: 'absolute',
                right: 15,
                top: 30,
                // bottom: 0,
                width: 'fit-content',
                backgroundColor: 'white',
                // border: '1px solid purple',
                padding: 5,
                boxShadow: '0 4px 15px -7px #000',
                borderRadius: 5,
                justifyContent: 'center',
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                color: 'black',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    fontSize: 18,
                    width: '90%',
                    margin: 5,
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                  }}
                >
                  Notification Settings:
                </div>
                <CloseIcon
                  style={styles.checkBox}
                  onClick={async (e) => {
                    e.stopPropagation();
                    setIsSettings(false);
                  }}
                />
              </div>
              <div
                style={styles.settings}
                onClick={async (e) => {
                  e.stopPropagation();
                  dispatch(setNotification('default'));
                }}
              >
                {notification === 'default' ? (
                  <CheckBoxIcon style={styles.checkBox} />
                ) : (
                  <CheckBoxOutlineBlankIcon style={styles.checkBox} />
                )}
                {/* <img
                src={mute}
                style={{ width: 17, height: 20, marginRight: 13 }}
              /> 
                Unmute Chat
              </div>
              <div
                style={styles.settings}
                onClick={async (e) => {
                  e.stopPropagation();
                  dispatch(setNotification('first'));
                }}
              >
                {notification === 'first' ? (
                  <CheckBoxIcon style={styles.checkBox} />
                ) : (
                  <CheckBoxOutlineBlankIcon style={styles.checkBox} />
                )}
                {/* <img
                src={mute}
                style={{ width: 17, height: 20, marginRight: 13 }}
              /> 
                Notify on First Message
              </div>
              <div
                style={styles.settings}
                onClick={async (e) => {
                  e.stopPropagation();
                  dispatch(setNotification('muted'));
                }}
              >
                {notification === 'muted' ? (
                  <CheckBoxIcon style={styles.checkBox} />
                ) : (
                  <CheckBoxOutlineBlankIcon style={styles.checkBox} />
                )}
                {/* <img
                src={unmute}
                style={{ width: 17, height: 20, marginRight: 13 }}
              /> 
                Mute Chat
              </div>
            </div>
          </div>
        )} */}
      </div>
    );
  }

  // Show collapsed sidebar
  return (
    <div
      style={{ ...styles.root, cursor: 'pointer' }}
      onClick={() => {
        dispatch(setChatWindow(true));
      }}
    >
      {/* <div style={styles.header}> */}
      <div
        style={{
          position: 'absolute',
          left: -25,
          // top: -10,
          width: 60,
          height: 60,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <MyEngagementZoneProfile />
      </div>

      <div
        style={{
          marginLeft: 30,
          fontSize: 16,
          fontWeight: 'bold',
          marginRight: 8,
          width: '50%',
        }}
      >
        Chat
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '20%',
        }}
      >
        <Tooltip
          title={
            online ? (
              <Typography variant="subtitle2">Open Chat Page</Typography>
            ) : (
              <Typography variant="subtitle2">
                User is Offline. Cannot perform action.
              </Typography>
            )
          }
        >
          <div
            style={{ margin: 8, cursor: 'pointer' }}
            onClick={() => {
              if (online) {
                console.log('location: ', window.location);
                let url = `${window.location.protocol}//${window.location.host}/engagementzone`;
                window.open(url, 'engagement_zone');
              }
            }}
          >
            <LaunchIcon />

            {/* <img src={exportIcon} style={{ width: 18, height: 18 }} /> */}
          </div>
        </Tooltip>
        {/* <div
        style={{ margin: 8 }}
        onClick={async (e) => {
          e.stopPropagation();
          setIsSettings(true);
        }}
      >
        <img src={setting} style={{ width: 18, height: 18 }} />
      </div> */}
        <a
          title={'Open'}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            dispatch(setChatWindow(true));
          }}
        >
          <KeyboardArrowUpIcon style={{ width: 30, height: 30 }} />
        </a>
      </div>
      {/* </div> */}
      {/* {isSettings && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: 'rgba(0,0,0,0.2)',
            flex: 1,
            borderBottomRightRadius: 20,
            borderBottomLeftRadius: 20,
            position: 'absolute',
            right: 0,
            top: 0,
            bottom: 0,
            left: 0,
            zIndex: 100000,

            // backgroundColor: 'green',
          }}
          onClick={async (e) => {
            e.stopPropagation();
            setIsSettings(false);
          }}
        >
          <div
            style={{
              position: 'absolute',
              right: 15,
              // top: 30,
              bottom: 0,
              width: 'fit-content',
              backgroundColor: 'white',
              // border: '1px solid purple',
              padding: 5,
              boxShadow: '0 4px 15px -7px #000',
              borderRadius: 5,
              justifyContent: 'center',
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              color: 'black',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  fontSize: 18,
                  width: '90%',
                  margin: 5,
                  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                }}
              >
                Notification Settings:
              </div>
              <CloseIcon
                style={styles.checkBox}
                onClick={async (e) => {
                  e.stopPropagation();
                  setIsSettings(false);
                }}
              />
            </div>
            <div
              style={styles.settings}
              onClick={async (e) => {
                e.stopPropagation();
                dispatch(setNotification('default'));
              }}
            >
              {notification === 'default' ? (
                <CheckBoxIcon style={styles.checkBox} />
              ) : (
                <CheckBoxOutlineBlankIcon style={styles.checkBox} />
              )}

              Unmute Chat
            </div>
            <div
              style={styles.settings}
              onClick={async (e) => {
                e.stopPropagation();
                dispatch(setNotification('first'));
              }}
            >
              {notification === 'first' ? (
                <CheckBoxIcon style={styles.checkBox} />
              ) : (
                <CheckBoxOutlineBlankIcon style={styles.checkBox} />
              )}

              Notify on First Message
            </div>
            <div
              style={styles.settings}
              onClick={async (e) => {
                e.stopPropagation();
                dispatch(setNotification('muted'));
              }}
            >
              {notification === 'muted' ? (
                <CheckBoxIcon style={styles.checkBox} />
              ) : (
                <CheckBoxOutlineBlankIcon style={styles.checkBox} />
              )}

              Mute Chat
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
}

const styles = {
  root: {
    display: 'flex',
    position: 'fixed',
    right: 20,
    bottom: 15,
    height: 50,
    width: 300,
    borderRadius: 50,
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: 'pink',
    color: 'white',
    padding: 10,

    boxShadow: '0 0 15px -7px #000',
    zIndex: 100000,
  },
  mobileRoot: {
    display: 'flex',
    position: 'fixed',
    right: 30,
    bottom: 30,
    borderRadius: 50,
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: '#122857',
    color: 'white',
    padding: 10,

    boxShadow: '0 0 15px -7px #000',
    zIndex: 100000,
  },
  chatRoot: {
    display: 'flex',
    position: 'fixed',
    right: 10,
    bottom: 60,
    // top: 150,
    height: 600,
    width: 300,
    backgroundColor: 'white',
    borderRadius: 20,
    // justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    boxShadow: '0 0 15px -7px #000',
    zIndex: 100000,
  },
  header: {
    width: '100%',
    height: 50,
    backgroundColor: '#122857',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: 15,
    alignItems: 'center',
    flexDirection: 'row',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    position: 'relative',
  },
  tabs: {
    margin: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 5,
    marginRight: 15,
    marginBottom: 0,
    borderColor: '#122857',
    cursor: 'pointer',
  },
  roomBanner: {
    width: '100%',
    // backgroundColor: 'gray',
    // color: 'white',
    padding: 5,
    paddingLeft: 5,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  chatRoom: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingTop: 5,
    paddingLeft: 15,
    paddingRight: 15,
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1.5px solid rgba(0, 0, 0, 0.12)',
    // boxShadow: '0 0 15px -7px #000',
  },
  circle: {
    borderRadius: 1,
    width: 40,
    height: 40,
    backgroundColor: '#122857',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,

    boxShadow: '0 0 15px -7px #000',
    zIndex: 100000,
  },
  settings: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-evenly',
    padding: 10,
    width: '100%',
    // border: '1px solid',
    fontSize: 16,
    cursor: 'pointer',
  },
  checkBox: {
    cursor: 'pointer',
    color: '#757575',
    marginRight: 5,
  },
};
