import { useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { getUser } from '../Store/Reducers/user';
import {
  useGetEventActionsQuery,
  useGetAttendeeActionsQuery,
} from '../Services/queryApi';
import { eventId } from '../config';

export default function LeaderboardScreen() {
  const user = useSelector(getUser);

  const {
    data: eventActions = [],
    error: eventActionsError,
    isLoading: eventActionsLoading,
  } = useGetEventActionsQuery(eventId);
  const {
    data: attendeeActions = [],
    error: attendeeActionsError,
    isLoading: attendeeActionsLoading,
  } = useGetAttendeeActionsQuery(user.attendee_id);

  const myPoints = {};
  let totalPoints = 0;
  eventActions.forEach((action) => {
    if (action.is_enabled) {
      const myAction = attendeeActions.find((ele) => ele.code === action.code);
      if (myAction?.total_points) {
        totalPoints +=
          typeof myAction.total_points == 'string'
            ? parseInt(myAction.total_points, 10)
            : myAction.total_points;
      }
      myPoints[action.action_id] = { ...action };
      myPoints[action.action_id].total_points = myAction?.total_points || 0;
    }
  });

  if (attendeeActionsLoading || eventActionsLoading) {
    return (
      <Grid container>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <CircularProgress color="inherit" />
          <Typography variant="h4" color="textPrimary">
            Loading...
          </Typography>
        </Grid>
      </Grid>
    );
  }
  if (eventActionsError || attendeeActionsError) {
    return (
      <Grid container>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography variant="h4" color="textPrimary">
            Error: {eventActionsError || attendeeActionsError}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    // @ts-ignore
    <TableContainer id="my-points-tabpanel" component={Paper}>
      <Table stickyHeader aria-label="my points table">
        <TableHead>
          <TableRow>
            <TableCell>Action</TableCell>
            <TableCell align="right">Points</TableCell>
            <TableCell align="right">Earned</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(myPoints).map((id, index) => (
            <TableRow key={`my_points_${id}`}>
              {myPoints[id].description ? (
                <Tooltip
                  title={myPoints[id].description}
                  aria-label={myPoints[id].description}
                >
                  <TableCell>{myPoints[id].name}</TableCell>
                </Tooltip>
              ) : (
                <TableCell>{myPoints[id].name}</TableCell>
              )}
              <TableCell align="right">{myPoints[id].points}</TableCell>
              <TableCell align="right">
                {myPoints[id].total_points} /{' '}
                {myPoints[id].points * myPoints[id].max_number}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={2}>Total:</TableCell>
            <TableCell align="right">{totalPoints}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
